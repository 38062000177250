import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouterDom from 'react-router-dom'
import * as Urql from 'urql'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import { Global, css } from '@emotion/core'

import * as Session from './session'
import * as Configuration from './configuration'
import * as Promotions from './promotions'
import * as Utils from './utils'
import { BannerCarousel } from './banner-carousel'
import { BetSlipOffsetBottom, MediaQueries } from './constants'
import { Divider } from './divider'
import { useMenuQueries } from './use-menu-queries'
import { useServerConfiguration } from './server-configuration'

const getBetbyToken = `
  query getBetbyToken {
    __typename
    player {
      __typename
      betbyToken
    }
  }
`

function usePreviousValue(value) {
  const ref = React.useRef(value)
  React.useEffect(() => {
    ref.current = value
  })
  return ref.current
}

function setOffsetTop() {
  if (window.innerWidth > 1000) {
    return 0
  } else {
    return 56
  }
}

export function SportsbookDirect(props) {
  const [response] = Urql.useQuery({
    query: getBetbyToken,
  })
  const { depositQuery, loginQuery, registerQuery } = useMenuQueries()
  const ref = React.useRef(null)
  const [shouldInitApp, setShouldInitApp] = React.useState(false)
  const [btRendererInited, setBtRendererInited] = React.useState(false)
  const [scrollbarWidth, setScrollbarWidth] = React.useState('0px')
  const history = ReactRouterDom.useHistory()
  const location = ReactRouterDom.useLocation()
  const sessionToken = ReactRedux.useSelector(state =>
    Session.getToken(state.session)
  )
  const apiUrl = ReactRedux.useSelector(state =>
    Configuration.getApiUrl(state.configuration)
  )
  const lobbyBannerPromotions = ReactRedux.useSelector(state =>
    Promotions.getLobbyBannerPromotions(state.promotions, 'sport')
  )
  const token = response?.data?.player?.betbyToken

  const { locale } = useServerConfiguration()
  const prevToken = usePreviousValue(token)
  const prevLanguage = usePreviousValue(locale.language)

  const sportsUrl = '/sports'

  const brandId = ReactRedux.useSelector(state =>
    Configuration.getBetbyBrandId(state.configuration)
  )
  const scriptUrl = ReactRedux.useSelector(state =>
    Configuration.getBetbyScriptUrl(state.configuration)
  )

  const betbyDataLoaded = !response.fetching && response.data != null

  // Makes sure client is available on window (script loaded properly)
  React.useEffect(() => {
    if (!btRendererInited && !response.fetching) {
      const timerId = window.setInterval(() => {
        setBtRendererInited(typeof window.BTRenderer !== 'undefined')
      }, 250)
      return () => {
        clearInterval(timerId)
      }
    }
  }, [btRendererInited, response.fetching])

  const client = React.useMemo(() => {
    if (process.browser && btRendererInited) {
      return new window.BTRenderer()
    }
  }, [process.browser, btRendererInited])

  const search = location?.search?.substr(1) || ''
  const searchParams = new URLSearchParams(search)
  const btPathQuery = searchParams.get('bt-path')

  React.useEffect(() => {
    if (btPathQuery && client) {
      client.updateOptions({ url: btPathQuery })
    }
  }, [btPathQuery])

  const widgetConfig = React.useMemo(
    () =>
      props.widgetName
        ? {
            widgetName: props.widgetName,
            widgetParams: {
              minFrameHeight: props.minFrameHeight,
              placeholder: props.widgetPlaceholder,
              onBannerClick: ({ url }) =>
                history.push(`${sportsUrl}?bt-path=${url}`),
            },
          }
        : {},
    [props.widgetName, props.minFrameHeight, props.widgetPlaceholder]
  )

  // Initiates client
  React.useEffect(() => {
    if (shouldInitApp) {
      setShouldInitApp(false)
      const offsetTop = setOffsetTop(token)
      const customBetslipButton = document.querySelector('#customBetslipButton')
      client.initialize({
        brand_id: brandId,
        token,
        themeName: 'default-table',
        lang: locale.language,
        target: document.querySelector('#betby'),
        betSlipOffsetBottom:
          window.screen.width < 800 ? BetSlipOffsetBottom : 0,
        betSlipOffsetTop: offsetTop,
        stickyTop: offsetTop,
        betslipZIndex: 100,
        cssUrls: [
          'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
        ],
        fontFamilies: ['Montserrat, sans-serif', 'Roboto, sans-serif'],
        ...widgetConfig,
        onSessionRefresh: () => window.location.reload(),
        goToClick: ({ url }) => history.push(`${sportsUrl}?bt-path=${url}`),
        onBannerClick: ({ url }) => history.push(`${sportsUrl}?bt-path=${url}`),
        onLogin: () => history.push(`?${loginQuery}`),
        onRecharge: () => history.push(`?${depositQuery}`),
        onRegister: () => history.push(`?${registerQuery}`),
        onSportClick: () => history.push(sportsUrl),
        onTokenExpired: () =>
          fetch(`${apiUrl}/my/betby_jwt_token`, {
            headers: {
              authorization: sessionToken ?? null,
              'content-type': 'application/json',
              accept: 'application/vnd.casinosaga.v1',
            },
            method: 'GET',
          })
            .then(res => res.json())
            .then(res => res.token)
            .catch(err => err),
        ...(customBetslipButton && { betSlipButton: customBetslipButton }),
      })
    }
  }, [locale, token, shouldInitApp, client])

  // Hook for checking if app should be reinited.
  React.useEffect(() => {
    // Do nothing if there is no refrence
    if (ref.current && btRendererInited && client) {
      // Config has been updated; e.g. language was switched.
      if (
        prevLanguage !== locale.language ||
        (prevToken === null && token != null)
      ) {
        client.kill()
        setShouldInitApp(true)
      }
    }
  }, [
    token,
    locale,
    prevToken,
    prevLanguage,
    ref,
    setShouldInitApp,
    btRendererInited,
    client,
  ])

  React.useEffect(() => {
    const queries = MediaQueries
    const mediaQueryLists = {}
    const keys = R.keys(queries)

    function handleChange(isMatching) {
      const match = R.filter(query => query, isMatching)
      const customBetslipButton = document.querySelector('#customBetslipButton')
      switch (R.head(R.keys(match))) {
        case 'sm': {
          client.updateOptions({
            stickyTop: 56,
            betSlipOffsetTop: 56,
            betSlipOffsetBottom: BetSlipOffsetBottom,
            ...(customBetslipButton && { betSlipButton: customBetslipButton }),
          })
          break
        }
        case 'md': {
          {
            client.updateOptions({
              stickyTop: 56,
              betSlipOffsetTop: 56,
              betSlipOffsetBottom: 0,
              ...(customBetslipButton && {
                betSlipButton: customBetslipButton,
              }),
            })
          }
          break
        }
        case 'lg': {
          client.updateOptions({
            stickyTop: 0,
            betSlipOffsetTop: 0,
            betSlipOffsetBottom: 0,
            ...(customBetslipButton && { betSlipButton: customBetslipButton }),
          })
          break
        }
      }
    }

    const handleQueryListener = () => {
      const isMatching = R.reduce(
        (acc, media) => {
          acc[media] = Boolean(
            mediaQueryLists[media] && mediaQueryLists[media].matches
          )
          return acc
        },
        {},
        R.keys(queries)
      )

      handleChange(isMatching)
    }

    R.forEach(media => {
      mediaQueryLists[media] = window.matchMedia(queries[media])
    }, keys)

    R.forEach(media => {
      mediaQueryLists[media].addListener(handleQueryListener)
    }, keys)

    return () => {
      R.forEach(media => {
        mediaQueryLists[media].removeListener(handleQueryListener)
      }, keys)
    }
  }, [client])

  React.useEffect(() => {
    if (process.browser) {
      setScrollbarWidth(window.innerWidth - document.body.clientWidth + 'px')
    }
  }, [process.browser])

  // Launch client
  React.useEffect(() => {
    if (ref.current && brandId && btRendererInited && !response.fetching) {
      setShouldInitApp(true)
    }
  }, [ref.current, brandId, btRendererInited, response.fetching])

  // Makes sure the betby sript is loaded only once
  React.useEffect(() => {
    if (document) {
      if (!document?.querySelector('#betby-script')) {
        const script = document.createElement('script')
        script.src = scriptUrl
        script.id = 'betby-script'
        document?.body?.appendChild(script)
      }
    }
  }, [])

  // Cleanup on unmount
  React.useEffect(() => {
    return () => {
      if (client) {
        client.kill()
      }
    }
  }, [client])

  if (!betbyDataLoaded) {
    return null
  }

  return (
    <React.Fragment>
      <Global
        styles={css`
          scrollbar-width: none;

          ::-webkit-scrollbar {
            display: none;
            width: 0px;
          }
        `}
      />
      <Common.Box
        width={
          props.widgetName
            ? '100%'
            : ['100vw', null, `calc(100vw - 375px - ${scrollbarWidth})`]
        }
      >
        <Common.Box pt={[1, 2]}>
        <BannerCarousel banners={lobbyBannerPromotions} /></Common.Box>
        {!Utils.isEmpty(lobbyBannerPromotions) && (
          <Common.Space pt={0} px={[1, 2]}>
            <Divider />
          </Common.Space>
        )}
        <Common.Box id="betby" ref={ref} />
      </Common.Box>
    </React.Fragment>
  )
}

SportsbookDirect.propTypes = {
  widgetName: PropTypes.string,
  widgetPlaceholder: PropTypes.string,
  minFrameHeight: PropTypes.number,
}
